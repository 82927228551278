import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"


const Availability = () => (
  <Layout>
    <SEO title="Welcome to House-in-the-Yard" /> 
    <h1>Availability and Booking</h1>
    <h2>How to Book</h2>
    <p>To book one of our cottages, simply check availability below and contact Noel and Delyth Sumner at:</p>
    <ul>
      <li><a href="tel:+44 (0) 7811 404268">+44 (0) 7811 404268</a></li>
      <li><a href="tel:+44 (0) 1248 440860">+44 (0) 1248 440860</a> </li>
      <li><a href="mailto: Noel and Delyth Sumner <thehouseintheyard@gmail.com>">thehouseintheyard@gmail.com.</a></li>
    </ul> 
    <p>House in the Yard holiday cottages are ideally located in the south west corner of the Isle of Anglesey (Sir Ynys Mon) in the village of Llangaffo; close to the beautiful  beaches and pine forests of Newborough.</p>
    <p>We look forward to hearing from you.</p>
    <p>Please note it is a minimum three night stay.</p>
    <h2 id="farmhouse">The Farmhouse</h2>
    <p><Link to="/the-farmhouse">Learn more about The Farmhouse</Link></p>
    <div className="calendar">
      <iframe title="Book House-in-the-Yard"  src="https://hity-dev.flywheelsites.com/book-farmhouse/" style={{border: `0`, width:`1000px`, height: `500px`}}></iframe>
    </div>
    
  
  
    <h2 id="owl">The Owl Barn</h2>    
    <p><Link to="/the-owl-barn">Learn more about The Owl Barn</Link></p>
    <div className="calendar">
      <iframe title="Book House-in-the-Yard" loading="lazy" src="https://hity-dev.flywheelsites.com/book-owl-barn" style={{border: `0`, width:`1000px`, height: `500px`}}></iframe>      
    </div>

    <h2 id="owl">The Granary</h2>
    <p><Link to="/the-granary">Learn more about The Granary</Link></p>
    <div className="calendar">
      <iframe title="Book House-in-the-Yard" loading="lazy" src="https://hity-dev.flywheelsites.com/book-granary" style={{border: `0`, width:`1000px`, height: `500px`}}></iframe>
    </div>
    
    <h2 id="owl">Woodpecker Cottage</h2>
    <p><Link to="/woodpecker-cottage">Learn more about Woodpecker Cottage</Link></p>
    <div className="calendar">
      <iframe title="Book House-in-the-Yard" loading="lazy" src="https://hity-dev.flywheelsites.com/book-woodpecker-cottage/" style={{border: `0`, width:`1000px`, height: `500px`}}></iframe>
    </div>
  </Layout>
)

export default Availability
